import { useEffect } from "react";
import {
  loadOneTagCompletedSignupScript,
  loadOneTagStartSignupScript,
  removeOneTagScript,
} from "../utility/oneTag";

export function useOneTagStartLoadScript() {
  useEffect(() => {
    if (process.env.NODE_ENV !== "production") return;

    const script = loadOneTagStartSignupScript();
    return () => removeOneTagScript(script);
  }, []);
}

export function useOneTagCompletedSignupLoadScript(accountId) {
  useEffect(() => {
    if (process.env.NODE_ENV !== "production") return;

    const script = loadOneTagCompletedSignupScript(accountId);
    return () => removeOneTagScript(script);
  }, [accountId]);
}

export function useSignUpTracking(accountId) {
  useEffect(() => {
    if (process.env.NODE_ENV !== "production") return;

    const trackingFunction = `
      (function() {
        var w = window, d = document;
        var s = d.createElement('script');
        s.setAttribute('async', 'true');
        s.setAttribute('type', 'text/javascript');
        s.setAttribute('src', '//c1.rfihub.net/js/tc.min.js');
        var f = d.getElementsByTagName('script')[0];
        f.parentNode.insertBefore(s, f);
        if (typeof w['_rfi'] !== 'function') {
          w['_rfi']=function() {
            w['_rfi'].commands = w['_rfi'].commands || [];
            w['_rfi'].commands.push(arguments);
          };
        }
        _rfi('setArgs', 'ver', '9');
        _rfi('setArgs', 'rb', '52589');
        _rfi('setArgs', 'ca', '20862636');
        _rfi('setArgs', '_o', '52589');
        _rfi('setArgs', '_t', '20862636');
        _rfi('setArgs', 'signupid', '${accountId}');
        _rfi('track');
      })();
    `;

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = trackingFunction;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [accountId]);
}

export function useRegistrationSelectionTracking() {
  useEffect(() => {
    if (process.env.NODE_ENV !== "production") return;

    const trackingFunction = `
      (function() {
        var w = window, d = document;
        var s = d.createElement('script');
        s.setAttribute('async', 'true');
        s.setAttribute('type', 'text/javascript');
        s.setAttribute('src', '//c1.rfihub.net/js/tc.min.js');
        var f = d.getElementsByTagName('script')[0];
        f.parentNode.insertBefore(s, f);
        if (typeof w['_rfi'] !== 'function') {
          w['_rfi']=function() {
            w['_rfi'].commands = w['_rfi'].commands || [];
            w['_rfi'].commands.push(arguments);
          };
        }
        _rfi('setArgs', 'ver', '9');
        _rfi('setArgs', 'rb', '49517');
        _rfi('setArgs', 'ca', '20848586');
        _rfi('setArgs', '_o', '49517');
        _rfi('setArgs', '_t', '20848586');
        _rfi('track');
      })();
    `;

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = trackingFunction;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
}

export function useAcuityAdsTracking(accountId) {
  useEffect(() => {
    if (process.env.NODE_ENV !== "production") return;

    const trackingFunction = `
      'use strict'
      !(function(a, e) {
          if (!a.aap) {
              a.aap = function(e) {
                  ;(a.acuityAdsEventQueue = a.acuityAdsEventQueue || []).push(e)
              }
              var t = 'script'
              var i = e.createElement(t)
              i.async = true
              i.src = 'https://origin.acuityplatform.com/event/v2/pixel.js'
              var c = e.getElementsByTagName(t)[0]
              c.parentNode.insertBefore(i, c)
              a.acuityPiggybackCallback = function(e) {
                  a.acuityParseResponse(e)
              }
          }
      })(window, document)
      aap({ pixelKey: '8440592298022269491' })

      aap({
          pixelKey: '8440592298022269491',
          pg: 36354,
          prodid: ${accountId},
          ordid: null,
          crev: null,
          delay: null,
      })
    `;

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = trackingFunction;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [accountId]);
}
