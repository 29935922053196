import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import "./App.scss";
import { BrowserRouter, Route, Switch, useLocation } from "react-router-dom";
import WizardContainer from "./component/wizard/wizard.container";
import ErrorPage from "./component/errorPage/errorPage.component";
import { withLocalize } from "react-localize-redux";
import VerificationEmail from "./component/VerificationEmail/verificationEmail.component";
import QuestionarioPepContainer from "./component/questionarioPep/questionarioPep.container";
import ErrorCardsPage from "./component/errorCardsPage/errorCardsPage.component";
import FollowUpContainer from "./component/followUp/followUp.container";
import VivochaProactiveChatDataErrors from "./component/vivochaDataErrors/vivochaProactiveChatDataErrors.component";
import RegistrationTypeSelectionWithOcrPage from "./component/registrationTypeSelectionWithOcrPage/registrationTypeSelectionWithOcrPage.component";
import SpidRegistration from "./component/spidRegistration/spidRegistration.component";
import SpidCallback from "./component/spidCallback/spidCallback.component";
import ThankYouPage from "./component/thankYouPage/thankYouPage.component";
import Jumio from "./component/jumio/jumio.container";
import { useInit } from "./hooks/useInit";
import { servicesSelectors } from "./store/services/services.selectors";
import FeatureEnabledRoute from "./router/FeatureEnabledRoute";
import SpidFiscalCode from "./component/spidFiscalCode/spidFiscalCode.component";
import ErrorCardBonusPage from "./component/errorCardBonusPage/errorCardBonusPage";
import UnmatchingPersonalDataPage from "./component/unmatchingPersonalDataPage/unmatchingPersonalDataPage.component";
import ActiveAccountExpired from "./component/activeAccount/activateAccountErrorPage/activateAccountErrorPage.component";
import RegistrationOCRSelection from "./component/registrationOCRSelection/registrationOCRSelection.component";
import OcrCallback from "./component/ocrCallback/ocrCallback.component";
import OcrPage from "./component/ocrPage/ocrPage.component";
import RegistrationOCRSelectionPdv from "./component/registrationOCRSelection/registrationOCRSelectionPdv.component";
import ThankYouPagePdv from "./component/thankYouPagePdv/thankYouPagePdv.component";
import DocumentSelectionPage from "./component/documentSelectionPage/documentSelectionPage.component";
import ErrorCardOcrPage from "./component/errorCardOcrPage/errorCardOcrPage.component";
import RegistrationPdv from "./component/registrationPdv/registrationPdv.component";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function Init() {
  useInit();
  return null;
}

const App = () => {
  const wizardConf = useSelector((state) => state.wizard.flow);
  const spidStatus = useSelector(servicesSelectors.getSpidStatus);

  return (
    <BrowserRouter basename="/registrazioneJwt">
      <Init />
      <ScrollToTop />
      <Switch>
        <Route exact path="/">
          {wizardConf.length > 0 && <WizardContainer />}
        </Route>
        {wizardConf.map((val) => {
          if (!val || !val.name) return null;

          return (
            <Route exact key={val.path} path={val.path}>
              <WizardContainer />
            </Route>
          );
        })}

        <Route path="/carica-documento" component={Jumio} />
        <Route path="/ThankYouPage" component={ThankYouPage} />
        <Route
          path="/registrazione-effettuata-pdv/:slug"
          component={ThankYouPagePdv}
        />
        <Route path="/errore-registrazione" component={ErrorPage} />
        <Route path="/errore" component={ErrorCardsPage} />
        <Route path="/errore-bonus" component={ErrorCardBonusPage} />
        <Route path="/errore-ocr" component={ErrorCardOcrPage} />
        <Route path="/verifica-email" component={VerificationEmail} />
        <Route path="/questionario" component={QuestionarioPepContainer} />
        <Route path="/registrazione-scaduta" component={ActiveAccountExpired} />
        <Route
          path="/riprendi-registrazione/:id"
          component={FollowUpContainer}
        />

        <FeatureEnabledRoute
          path="/seleziona-registrazione"
          enabled={spidStatus}
          component={RegistrationTypeSelectionWithOcrPage}
        />
        <FeatureEnabledRoute
          path="/registrazione-spid-cf"
          enabled={spidStatus}
          component={SpidFiscalCode}
        />
        <FeatureEnabledRoute
          path="/registrazione-spid"
          enabled={spidStatus}
          component={SpidRegistration}
        />
        <Route path="/spid-result-callback" component={SpidCallback} />
        <Route
          path="/errore-dati-non-corrispondenti"
          component={UnmatchingPersonalDataPage}
        />
        <Route path="/seleziona-ocr" component={RegistrationOCRSelection} />
        <Route
          path="/ocr-callback/:regType/:accountId/:workflowExecutionId"
          component={OcrCallback}
        />
        <Route path="/ocr/:regType" component={OcrPage} />
        <Route
          path="/registrazione-semplificata-pdv"
          component={RegistrationOCRSelectionPdv}
        />
        <Route
          path="/seleziona-documento/:regType"
          component={DocumentSelectionPage}
        />
        <Route path="/registrazione-pdv" component={RegistrationPdv} />
      </Switch>
      <VivochaProactiveChatDataErrors />
    </BrowserRouter>
  );
};

export default withLocalize(App);
