import axios from "axios";
import { addTranslationForLanguage, initialize } from "react-localize-redux";
import { v4 as uuidv4 } from "uuid";
import {
  DEFAULT_ENDCALLBACKURL,
  KEY_RE_CAPTCHA,
  MAIN_SITE_HOMEPAGE,
} from "../config";
import {
  getDefaultClassicFlowName,
  getDefaultFlowName,
} from "../services/flowController";
import { getLabels } from "../services/languageController";
import { getAllServiceStatus } from "../services/serviceStatusController";
import store from "../store.config";
import { getDocumentTypeAPI } from "../store/documentType/documentType.action";
import * as formDataAction from "../store/formData/formData.action";
import { formDataSelectors } from "../store/formData/formData.selectors";
import { disableSpid, enableSpid } from "../store/services/services.action";
import { servicesSelectors } from "../store/services/services.selectors";
import * as flowAction from "../store/wizard/wizard.action";
import { setFlusso, stepNext } from "../store/wizard/wizard.action";
import {
  wizardSelectorsFlow,
  wizardSelectorsFlusso,
} from "../store/wizard/wizard.selectors";
import { deleteCookie, readCookie, setCookie } from "./cookie";
import { loadOneTagAllPagesScript } from "./oneTag";
import { canRedirectAutomatically } from "./route";
import { getFlow, wizardConfReq } from "./wizardConfig/wizardConfig";

function loadRecaptchaScript() {
  const script = document.createElement("script");
  script.src =
    "https://www.google.com/recaptcha/enterprise.js?render=" + KEY_RE_CAPTCHA;

  document.body.appendChild(script);
}

export async function getLabelsAPI() {
  const activeLanguage =
    window.navigator.userLanguage || window.navigator.language;

  try {
    const data = await getLabels(activeLanguage);

    store.dispatch(addTranslationForLanguage(data.labels, data.language));
  } catch (err) {
    console.error(err);
  }
}

function setChannel(a) {
  const isMobile =
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
      a,
    ) ||
    // eslint-disable-next-line no-useless-escape
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
      a.substr(0, 4),
    );
  store.dispatch(formDataAction.addFormData({ isMobile }));
}

export function queryString() {
  setChannel(navigator.userAgent || navigator.vendor);
  var urlParams = new URLSearchParams(window.location.search);

  handleUrlParamWithCookie("siteid", "channel");

  const codicePromozione = urlParams.has("codicePromozione")
    ? urlParams.get("codicePromozione")
    : null;
  if (codicePromozione) {
    setPromoCodeUrl(codicePromozione);
    setCookie("codice_promozione", codicePromozione, 1800);
  }

  if (urlParams.has("flow")) {
    const flow = urlParams.get("flow");

    store.dispatch(setFlusso(flow));
    setCookie("FLOW", flow);
  }

  let mppartern = false;
  if (urlParams.has("mppartner")) {
    document.cookie =
      "MPPARTNER=" +
      urlParams.get("mppartner") +
      "; domain=.sisal.it; path=/; samesite";
    store.dispatch(
      formDataAction.addFormData({ mpPartner: urlParams.get("mppartner") }),
    );
    mppartern = true;
  }

  if (urlParams.has("pdv") && urlParams.get("pdv") === "true")
    store.dispatch(
      formDataAction.addFormData({ fromPdv: urlParams.get("pdv") === "true" }),
    );
  else {
    store.dispatch(formDataAction.addFormData({ fromPdv: false }));
    store.dispatch(stepNext(0));
  }

  const defaultCancelCallbackUrl =
    formDataSelectors.getFormData(store.getState()).channel === "62"
      ? MAIN_SITE_HOMEPAGE
      : "https://appmobile.sisal.it/?appFunc=close";
  handleUrlParamWithCookie(
    "cancelcallbackurl",
    "cancelcallbackurl",
    defaultCancelCallbackUrl,
  );

  handleUrlParamWithCookie(
    "endcallbackurl",
    "endcallbackurl",
    DEFAULT_ENDCALLBACKURL,
  );

  if (urlParams.has("errorcallbackurl")) {
    store.dispatch(
      formDataAction.addFormData({
        errorcallbackurl: urlParams.get("errorcallbackurl"),
      }),
    );
  }
  if (urlParams.has("jumpTo")) {
    store.dispatch(stepNext(urlParams.get("jumpTo") - 1));
  }
  var uri = window.location.toString();
  if (uri.indexOf("?") > 0) {
    document.cookie = `ParametersCookie=${uri.substring(uri.indexOf("?") + 1)}`;
  }
  if (!mppartern) {
    let name = "MPPARTNER=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        store.dispatch(
          formDataAction.addFormData({
            mpPartner: c.substring(name.length, c.length),
          }),
        );
        break;
      }
    }
  }
}

/**
 * Handle urlparam with cookie if siteid is not present in url because
 * in SPID callback flow siteid is not present in url. Read urlparam
 * and then set it in Redux in the corresponding formDataEntry. If no urlParam
 * nor cookie are present, a default value can be provided.
 *
 * @param {string} urlParam
 * @param {string} formDataEntry
 * @param {string=} defaultFormDataEntry
 */
function handleUrlParamWithCookie(
  urlParam,
  formDataEntry,
  defaultFormDataEntry,
) {
  const urlParams = new URLSearchParams(window.location.search);

  const paramFromUrlParam = urlParams.has(urlParam)
    ? urlParams.get(urlParam)
    : null;
  const paramFromCookie = readCookie(urlParam);
  const param = paramFromUrlParam || paramFromCookie || defaultFormDataEntry;

  if (param) {
    store.dispatch(formDataAction.addFormData({ [formDataEntry]: param }));
  }
  if (paramFromUrlParam) {
    setCookie(urlParam, paramFromUrlParam, 1800);
  }
}

export function cleanUrl(history) {
  const ignoreCleanUrlRouteList = [
    "errore-registrazione",
    "verifica-email",
    "ThankYouPage",
    "ThankYouPageNew",
    "questionario",
    "errore",
    "summary",
    "spid-result-callback",
    "ocr-callback",
  ];

  const ignoreCleanUrl = ignoreCleanUrlRouteList.some((value) =>
    history.location.pathname.includes(value),
  );

  if (!history.location.search || ignoreCleanUrl) {
    return;
  }

  history.replace(history.location.pathname);
}

export function savePublicIp() {
  function getPublicIp(url) {
    return axios.get(url).then((res) => res.data);
  }

  function resolveFn(resp) {
    if (!resp) return;
    const ip = resp.replace(/(\r\n|\n|\r)/gm, "");
    store.dispatch(formDataAction.addFormData({ ipAddress: ip }));
  }

  const primaryIpProviderUrl = "https://ipv4.icanhazip.com/";
  const secondaryIpProviderUrl = "https://api.ipify.org/";

  return getPublicIp(primaryIpProviderUrl)
    .then(resolveFn)
    .catch(() => getPublicIp(secondaryIpProviderUrl).then(resolveFn));
}

export function backBrowser(history) {
  const unlisten = history.listen((location, action) => {
    if (action !== "POP") {
      return;
    }

    const routeName = history.location.pathname.replace(
      "/registrazioneJwt/",
      "/",
    );
    wizardConfReq().then((wizard) => {
      wizard.forEach((val) => {
        if (val.path === routeName) {
          store.dispatch(flowAction.setComponent(val));
        }
      });
    });
  });

  return unlisten;
}

export function initializeLocalizeRedux() {
  const languages = {
    languages: [
      { name: "Italian", code: "it-IT" },
      { name: "English", code: "en-EN" },
      { name: "EnglishUS", code: "en-US" },
    ],
    options: {
      renderToStaticMarkup: false,
      renderInnerHtml: true,
    },
  };
  store.dispatch(initialize(languages));
}

export function setSessionIdAndUserAgent() {
  store.dispatch(
    formDataAction.addFormData({
      sessionId: uuidv4(),
      userAgent: navigator.userAgent,
    }),
  );
}

export async function setDefaultFlowNames() {
  const [defaultFlowNameResponse, defaultClassicFlowNameResponse] =
    await Promise.all([getDefaultFlowName(), getDefaultClassicFlowName()]);
  store.dispatch(
    flowAction.setDefaultFlowName(defaultFlowNameResponse.defaultFlow),
  );
  store.dispatch(
    flowAction.setDefaultClassicFlowName(
      defaultClassicFlowNameResponse.defaultFlow,
    ),
  );
}

export function initSpidOrClassicReg(history) {
  const state = store.getState();

  const flowFromState = wizardSelectorsFlusso.getFlusso(state);
  const flowFromCookie = readCookie("FLOW");
  const defaultFlowNameFromBackend =
    wizardSelectorsFlow.getWizardDefaultFlowName(state);

  const defaultFlowName = calcDefaultFlowName(
    flowFromState,
    flowFromCookie,
    defaultFlowNameFromBackend,
  );

  const defaultClassicFlowName =
    wizardSelectorsFlow.getWizardDefaultClassicFlowName(state);

  const spidEnabled = servicesSelectors.getSpidStatus(state);

  const fromPdv = state.formDataReducer.formData.fromPdv;
  const redirectToSelectionPage = shouldRedirectToSelectionPage(
    fromPdv,
    spidEnabled,
    defaultFlowName,
  );

  if (redirectToSelectionPage) {
    const searchParams = new URLSearchParams(window.location.search);
    const ocrEnabled = searchParams.get("ocr") === "true";

    history.replace({
      pathname: ocrEnabled
        ? "/seleziona-registrazione-ocr"
        : "/seleziona-registrazione",
      search: searchParams.toString(),
    });
  } else {
    const flowName = getActualFlowName(
      fromPdv,
      spidEnabled,
      defaultFlowName,
      defaultClassicFlowName,
    );

    handlePromoCodeCookie(flowName);

    const callbackPages = [
      "/riprendi-registrazione",
      "/spid-result-callback",
      "/ocr-callback",
    ];
    const isFromCallbackRedirectPage = callbackPages.some((callbackPage) =>
      history.location.pathname.includes(callbackPage),
    );

    if (!isFromCallbackRedirectPage) {
      // if coming from any callback redirect page, the flow is set in a custom way in the corresponding page
      getFlow(flowName, null, history);
    }
  }
}

export async function setServicesStatus() {
  const data = await getAllServiceStatus();
  return store.dispatch(
    data.allServicesEnabled.SPID === true ? enableSpid() : disableSpid(),
  );
}

export function getDocumentType(history) {
  return getDocumentTypeAPI(
    store.dispatch,
    store.getState().formDataReducer.formData.channel,
    history,
  )();
}

function handlePromoCodeCookie(flowName) {
  if (flowName === "SPID" && readCookie("codice_promozione")) {
    // since codicePromozione is not passed as a query param in the SPID callback flow
    // we need to check if it is present in the cookie
    setPromoCodeUrl(readCookie("codice_promozione"));
  } else {
    // in all other cases we can just use the query param and don't need the cookie anymore
    deleteCookie("codice_promozione");
  }
}

export function shouldRedirectToSelectionPage(
  fromPdv,
  spidEnabled,
  defaultFlowName,
) {
  return (
    !fromPdv &&
    spidEnabled &&
    defaultFlowName === "SPID" &&
    canRedirectAutomatically()
  );
}

export function calcDefaultFlowName(
  flowFromReduxState,
  flowFromCookie,
  defaultFlowFromBackend,
) {
  return flowFromReduxState || flowFromCookie || defaultFlowFromBackend;
}

export function getActualFlowName(
  fromPdv,
  spidEnabled,
  defaultFlowName,
  defaultClassicFlowName,
) {
  return fromPdv || (!spidEnabled && defaultFlowName === "SPID")
    ? defaultClassicFlowName
    : defaultFlowName;
}

function setPromoCodeUrl(promoCodeUrl) {
  store.dispatch(formDataAction.addFormData({ promoCodeUrl }));
}

export function initApp() {
  loadRecaptchaScript();
  if (process.env.NODE_ENV === "production") {
    loadOneTagAllPagesScript();
  }
}
