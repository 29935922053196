import { Translate } from "react-localize-redux";
import { useSelector } from "react-redux";
import close from "../../../images/svg/icon/close-upfile-white.svg";
import triangle from "../../../images/svg/triangle.svg";
import { formDataSelectors } from "../../../store/formData/formData.selectors";
export default function Tooltip({ handleCloseTooltip }) {
  const { documentTypeObj } = useSelector(formDataSelectors.getFormData);

  const document = documentTypeObj.description;

  const documentContainerSize =
    document === DocType.IdentityCard
      ? " tooltip-size-idCard"
      : document === DocType.DriverLicense
        ? " tooltip-size-driver-license"
        : " tooltip-size-passport";

  return (
    <div className={`tooltip-container ${documentContainerSize}`}>
      <img className="img-triangle" src={triangle} alt="triangle" />

      <img
        onClick={handleCloseTooltip}
        className="img-close"
        src={close}
        alt="close"
      />

      <span
        className={
          "img-documentId-type " +
          (document === DocType.IdentityCard
            ? "img-id-card"
            : document === DocType.DriverLicense
              ? "img-driver-license"
              : "img-passport")
        }
      />

      <div className="tooltip-title">
        <Translate id="text.whereIsDocNumber" />
      </div>

      <div className="tooltip-info">
        {document === DocType.IdentityCard ? (
          <Translate id="text.idCardDocNumber" />
        ) : document === DocType.DriverLicense ? (
          <Translate id="text.driverLicenseDocNumber" />
        ) : (
          <Translate id="text.passportDocNumber" />
        )}
      </div>

      {document === DocType.IdentityCard && (
        <div className="bottom-desc">
          <Translate id="text.idCardLocation" />
        </div>
      )}
    </div>
  );
}

const DocType = {
  IdentityCard: "Carta d'identità",
  DriverLicense: "Patente Italiana",
  PASSPORT: "Passaporto",
};
