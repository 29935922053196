import { Translate } from "react-localize-redux";
import { useSelector } from "react-redux";
import { DEFAULT_ENDCALLBACKURL } from "../../../config";
import logo from "../../../images/svg/logo-sisal.svg";
import { formDataSelectors } from "../../../store/formData/formData.selectors";
import { useAuthAction, useCustomRedirect } from "../thankYouPage.hooks";

export default function ThankYouPageHeader() {
  const { channel } = useSelector(formDataSelectors.getFormData);

  const { authenticate } = useAuthAction();
  const { customRedirect } = useCustomRedirect();

  const handleClick = async () => {
    await authenticate();
    customRedirect(DEFAULT_ENDCALLBACKURL);
  };

  if (channel === "67") return null;

  return (
    <header className="sisal-header">
      <div className="container-fluid">
        {!CUSTOM_HEADER_CHANNEL_IDS.includes(channel) ? (
          <>
            <button className="btn" onClick={handleClick} title="Sisal">
              <img className="logoThank logo" alt="logo" src={logo} />
            </button>
            <button
              type="button"
              className="btn btn-discard px-0"
              onClick={handleClick}
            >
              <Translate id="lbl.buttonDiscard" />
            </button>
          </>
        ) : (
          <button title="Sisal" className="btn">
            <img className="logoThank logo" alt="logo" src={logo} />
          </button>
        )}
      </div>
    </header>
  );
}

const CUSTOM_HEADER_CHANNEL_IDS = ["45", "41", "06", "49", "37", "21"];
