import { useEffect, useState } from "react";
import { Translate } from "react-localize-redux";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { KEY_RE_CAPTCHA } from "../../config";
import { useRegFlusso } from "../../hooks/useAnalytics";
import { useFlow } from "../../hooks/useFlow";
import { useTranslate } from "../../hooks/useTranslate";
import spidArrowLeft from "../../images/svg/wizardBtn/arrowLeft.svg";
import spidArrowRight from "../../images/svg/wizardBtn/arrowRight.svg";
import spidArrowRightDisabled from "../../images/svg/wizardBtn/arrowRightDisabled.svg";
import { checkBonusByCode } from "../../services/bonusController";
import { generateOtp } from "../../services/otpController";
import { openRechargeLimitApprovalPopupInRechargeLimitStep } from "../../store/rechargeLimit/rechargeLimit.action";
import { rechargeLimitSelectors } from "../../store/rechargeLimit/rechargeLimit.selectors";
import { getRegFlusso } from "../../utility/analytics";
import { useContactsBackendValidation } from "../contacts/contacts.hooks";
import { useFiscalCodeNextStep } from "../fiscalCode/fiscalCode.hooks";
import { getActiveBonusList } from "../welcomeBonus/welcomeBonus.helpers";
import { useWelcomeBonusFetch } from "../welcomeBonus/welcomeBonus.hooks";

export default function WizardBtn({
  btnDisabled,
  formData,
  formStatus,
  spidStatus,
  setComponent,
  component,
  bonus,
  setBonus,
  flow,
  flusso,
  isVisiblePhonePopup,
  isVerifiedPhone,
  setIsVisiblePhonePopup,
  setIsPhonePopupOtpSended,
  setIsOtpNotValid,
  setOtpBlockStatus,
  utagLink,
  addFormStatus,
  addFormData,
}) {
  const fetchWelcomeBonus = useWelcomeBonusFetch();

  const titleOcr =
    formData.documentTown !== "" &&
    formData.expirationDate !== "" &&
    formData.number !== "" &&
    formData.releaseDate !== "";

  const history = useHistory();

  const { goToNextStep, goToPreviousStep, goToSpecificStep } = useFlow();

  const [group, setGroup] = useState(history.location.pathname);

  const dispatch = useDispatch();

  const reg_flusso = useRegFlusso();

  const { getTranslatedString } = useTranslate();

  const approvalRechargeLimit = useSelector(
    rechargeLimitSelectors.getRechargeLimitApprovalAmount,
  );

  const newArray = flow.filter((el) => el.displayStep === true);

  const { validatePhoneAPI, validateEmailAPI } = useContactsBackendValidation();

  const { handleFiscalCodeNextStep } = useFiscalCodeNextStep();

  const stepBackCheck = () => {
    switch (flusso) {
      case "C1":
        if (component.previousComponentName === "/registrazione-pdv") {
          addFormStatus({
            usernamePdv: "",
            accountPin: "",
            chargingPin: "",
          });
          addFormData({
            usernamePdv: "",
            accountPin: "",
            chargingPin: "",
          });

          goToSpecificStep("/registrazione-pdv");
        } else if (component.name === "welcomeBonus" && !!bonus) {
          if (
            formData.bonusCode !== bonus.bonusCode &&
            formData.bonusCode !== ""
          ) {
            const newItem = formData.bonusList.findIndex(
              (obj) => obj.bonusCode === formData.bonusCode,
            );
            setBonus(formData.bonusList[newItem]);
          } else if (formData.bonusCode === "" && formData.promoCode !== "") {
            let item = formData.bonusList.findIndex(
              (obj) => obj.bonusCode === formData.promoCode,
            );
            if (item !== -1) {
              setBonus(formData.bonusList[item]);
            } else {
              let description =
                "Il codice promozioni inserito è corretto, completa la registrazione per riscattare il tuo bonus";
              const newItem = {
                bonusCode: formData.promoCode,
                title: formData.promoCode,
                bonusTitleLightBox: formData.promoCode,
                description: description,
                style: "bonus",
              };
              setBonus(newItem);
            }
          }

          goToPreviousStep();
        } else if (!component.previousComponentName) {
          history.push("/seleziona-ocr");
        } else {
          goToPreviousStep();
        }
        break;

      default:
        switch (component.name) {
          case "personalData":
            history.goBack();
            break;
          case "residence":
            if (formData.generatedFiscalCode) {
              goToPreviousStep();
            } else {
              goToSpecificStep("/codice-fiscale");
            }

            break;
          case "birthplace":
            addFormData({ generatedFiscalCode: false });
            goToPreviousStep();
            break;
          default:
            if (
              component.previousComponentName === "/indirizzo-residenza" &&
              component.name !== "domicile"
            ) {
              if (formData.sameResidenceAndDomicile) {
                goToPreviousStep();
              } else {
                goToSpecificStep("/indirizzo-residenza");
              }
            } else if (component.name === "welcomeBonus" && !!bonus) {
              if (
                formData.bonusCode !== bonus.bonusCode &&
                formData.bonusCode !== ""
              ) {
                const newItem = formData.bonusList.findIndex(
                  (obj) => obj.bonusCode === formData.bonusCode,
                );
                setBonus(formData.bonusList[newItem]);
              } else if (
                formData.bonusCode === "" &&
                formData.promoCode !== ""
              ) {
                let item = formData.bonusList.findIndex(
                  (obj) => obj.bonusCode === formData.promoCode,
                );

                if (item !== -1) {
                  setBonus(formData.bonusList[item]);
                } else {
                  let item = formData.bonusListAll.findIndex(
                    (obj) => obj.bonusCode === formData.promoCode,
                  );

                  if (item !== -1) {
                    setBonus(formData.bonusListAll[item]);
                  } else {
                    let description =
                      "Il codice promozioni inserito è corretto, completa la registrazione per riscattare il tuo bonus";
                    const newItem = {
                      bonusCode: formData.promoCode,
                      title: formData.promoCode,
                      bonusTitleLightBox: formData.promoCode,
                      description: description,
                      style: "bonus",
                    };
                    setBonus(newItem);
                  }
                }
              }
              goToPreviousStep();
            } else {
              goToPreviousStep();
            }
            break;
        }
        break;
    }
  };

  const stepNextCheck = async () => {
    if (component.name === "personalData") {
      goToNextStep();
    }
    if (
      component.name === "identification" &&
      formData &&
      formData.frontFileName === ""
    ) {
      goToSpecificStep("/documento-inserimento-dati");
    } else if (
      component.name === "residence" &&
      !formData.sameResidenceAndDomicile
    ) {
      if (component.dependencies) {
        const val = flow.find((v) => v.path === component.dependencies);
        if (val) {
          setComponent(val);
        }
        history.push(component.dependencies);
      } else {
        goToNextStep();
      }
    } else if (component.name === "termsAndConditions") {
      goToNextStep();
    } else if (component.name === "fiscalCode") {
      await handleFiscalCodeNextStep();
    } else if (component.name === "contacts") {
      if (formData.phoneNumber !== formData.phoneNumberPdv) {
        const isValidPhone = await validatePhoneAPI(formData.phoneNumber);

        if (!isValidPhone) {
          // error code is already handled in validatePhoneAPI
          return;
        }
      }

      const isValidEmail = await validateEmailAPI(formData.email);

      if (!isValidEmail) {
        // error code is already handled in validateEmailAPI
        return;
      }

      const isVerifiedDiv =
        flusso !== "SPID" || (flusso === "SPID" && spidStatus.showPhone)
          ? document
              .getElementById("it-ar-contacts-col-tel")
              .getElementsByClassName("success")
          : null;

      if (isVerifiedPhone || (isVerifiedDiv && isVerifiedDiv.length > 0)) {
        goToNextStep();
      } else if (component.useOtp && !isVerifiedPhone && !isVisiblePhonePopup) {
        handleOtp();
      } else {
        goToNextStep();
      }
    } else if (component.name === "userData") {
      const bonusList = await fetchWelcomeBonus();
      const validBonusCodeViaUrl = await hasValidBonusCodeViaUrl(
        formData.promoCodeUrl,
        formData.channel,
        formData.codiceRice,
      );

      const shouldGoToBonusErrorPage =
        !validBonusCodeViaUrl &&
        (bonusList == null ||
          !getActiveBonusList(bonusList, formData.channel).length);

      if (shouldGoToBonusErrorPage) {
        dispatch(
          utagLink({
            reg_step: "scelta-bonus",
            conversion_pagename: "scelta-bonus",
            error_message: getTranslatedString(
              "err.bonusUnavailable.card.body",
            ),
            reg_flusso,
            reg_event: "registrazione|error-message|bonus-non-disponibili",
          }),
        );

        history.push("/errore-bonus");
        return;
      }

      goToNextStep();
    } else if (component.name === "welcomeBonus" && !!bonus) {
      if (formData.bonusCode !== bonus.bonusCode && formData.bonusCode !== "") {
        const newItem = formData.bonusList.findIndex(
          (obj) => obj.bonusCode === formData.bonusCode,
        );
        setBonus(formData.bonusList[newItem]);
      } else if (formData.bonusCode === "" && formData.promoCode !== "") {
        let item = formData.bonusList.findIndex(
          (obj) => obj.bonusCode === formData.promoCode,
        );

        if (item !== -1) {
          setBonus(formData.bonusList[item]);
        } else {
          let description =
            "Il codice promozioni inserito è corretto, completa la registrazione per riscattare il tuo bonus";
          const newItem = {
            bonusCode: formData.promoCode,
            title: formData.promoCode,
            bonusTitleLightBox: formData.promoCode,
            description: description,
            style: "bonus",
          };
          setBonus(newItem);
        }
      }

      goToNextStep();
    } else if (component.name === "document") {
      if (!formData.hasDocFile || !formData.ocrData) {
        goToSpecificStep("/documento-inserimento-dati");
      } else {
        if (formData.ocrData && titleOcr) {
          goToSpecificStep("/documento-conferma-dati");
        } else {
          goToSpecificStep("/documento-inserimento-dati-mancanti");
        }
      }
    } else if (component.name === "rechargeLimit") {
      utagLink({
        reg_cta: "continua",
        reg_flusso: getRegFlusso(flusso, formData.fromPdv),
        reg_event: "registrazione|limite-ricarica|continua",
        reg_limite_ricarica: formData.rechargeLimit,
      });

      const rechargeLimit = parseInt(formData.rechargeLimit?.replace(".", ""));

      if (
        !isNaN(rechargeLimit) &&
        rechargeLimit > approvalRechargeLimit / 100
      ) {
        dispatch(openRechargeLimitApprovalPopupInRechargeLimitStep());
      } else {
        goToNextStep();
      }
    } else {
      goToNextStep();
    }
  };

  const changeGroup = (event) => {
    if (event && event.target && event.target.value) {
      setGroup(event.target.value);
      const val = flow.find((v) => v.path === event.target.value);
      if (val) {
        setComponent(val);
      }
      history.push(event.target.value);
    }
  };

  const handleOtp = () => {
    if (formStatus.phoneNumber === "err.phoneNumber.alreadypresent") {
      return;
    }

    const prefix = "+" + formData.phonePrefix.callingCodes;
    window.grecaptcha.enterprise
      .execute(KEY_RE_CAPTCHA, {
        action: "GENERATE_REG_OTP",
      })
      .then((token) => {
        generateOtp(formData.phoneNumber, prefix, token, formData.channel)
          .then(async (data) => {
            setOtpBlockStatus({
              blocked: false,
              invalided: false,
            });
            setIsOtpNotValid(false);
            setIsPhonePopupOtpSended(false);
            if (data.counterRefresh > 2) {
              setOtpBlockStatus({
                blocked: true,
                invalided: false,
              });
            }
            setIsVisiblePhonePopup(true);
          })
          .catch((err) => {
            const errInfo = err.response.data.error.errorInfos.generic[0];

            if (err.response.status === 422 && errInfo !== "err.void") {
              setIsOtpNotValid(false);
              setIsPhonePopupOtpSended(false);

              switch (errInfo) {
                case "err.otp.block":
                  setOtpBlockStatus({
                    blocked: true,
                    invalided: true,
                  });
                  break;
                case "err.out.limit":
                  setIsOtpNotValid(true);
                  break;
                case "err.otp.alreadySent":
                  setIsPhonePopupOtpSended(true);
                  break;
                default:
                  break;
              }

              setIsVisiblePhonePopup(true);
            }
          });
      });
  };

  const hasPersonalData = () => {
    if (
      formData.name !== "" &&
      formData.surname !== "" &&
      formData.fiscalCode !== "" &&
      formData.city !== "" &&
      formData.cap !== "" &&
      formData.address !== ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    hasPersonalData();
  }, [component]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const onEnterKeyPressed = (event) => {
      const modalOpen = document.querySelector(".popup-modal-overlay") != null;

      if (
        event.key === "Enter" &&
        component.name !== "summary" &&
        !btnDisabled &&
        !modalOpen &&
        !isVerifiedPhone &&
        !isVisiblePhonePopup
      )
        stepNextCheck();
    };

    document.addEventListener("keydown", onEnterKeyPressed);
    return () => document.removeEventListener("keydown", onEnterKeyPressed);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [btnDisabled, component, isVerifiedPhone, isVisiblePhonePopup]);

  const showNextBtn = () => {
    switch (component.name) {
      case "summary":
      case "activeAccount":
        return false;
      case "termsAndConditions":
        return flusso !== "SPID" && flusso !== "OCR";
      case "welcomeBonus":
        return flusso !== "OCRPDV" && flusso !== "PDV";
      default:
        return true;
    }
  };

  const showBackBtn = () => {
    // execption for the PDV flow, it should show the back button but not to
    // redirect to a previous step in the flow, but rather to a static page,
    // the same as the browser's back button
    if (flusso === "PDV" && component.name === "personalData") return true;

    if (component.path === "/") return false;

    if (formData.fromPdv && component.path === "/registrazione-pdv/wb-pdv") {
      return false;
    }

    switch (component.name) {
      case "summary":
      case "activeAccount":
        return false;
      case "termsAndConditions":
        return flusso !== "SPID" && flusso !== "OCR";
      case "welcomeBonus":
        return flusso !== "OCRPDV" && flusso !== "PDV";
      default:
        return true;
    }
  };

  return (
    <div
      className="container step-actions-container mt-5 wizard-btn-container"
      id="wizardBtn"
    >
      <div className="row">
        <div className="col-12 col-lg-6 offset-0 offset-lg-3 px-sm-0">
          <div className={`row wizard-btn-back mr-sm-0 wizard-row`}>
            <div className="col col-fluss1">
              {showBackBtn() && (
                <button
                  type="button"
                  id="btn_indietro"
                  className="btn-back"
                  onKeyPress={(e) => e.preventDefault()}
                  onClick={() => stepBackCheck()}
                >
                  <i className="icon-btn-left">
                    <img src={spidArrowLeft} width="24" alt="" />
                  </i>
                </button>
              )}
            </div>

            <div className="col col-fluss1">
              {component.displayStep === true ? (
                <div id="it-ar-wizardbutton-stepper" className="notshow">
                  <select
                    className="dynamic-input"
                    onChange={(e) => changeGroup(e)}
                    defaultValue={group}
                  >
                    {newArray.map((val) => (
                      <option key={val.path} value={val.path}>
                        {val.group}
                      </option>
                    ))}
                  </select>
                  <br />
                  <span className="pressEnter">
                    Select a group of steps, <br /> or continue step by step
                  </span>
                </div>
              ) : null}
            </div>

            {showNextBtn() && (
              <div className={`text-center wizard-btn wizard-btn-flex`}>
                <button
                  type="submit"
                  disabled={btnDisabled}
                  className="btn-next"
                  onMouseDown={() => stepNextCheck()}
                  id="btn_continua"
                >
                  <span>
                    <Translate id="lbl.continuous" />
                  </span>
                  <i className="icon-btn-right">
                    <img
                      src={
                        btnDisabled ? spidArrowRightDisabled : spidArrowRight
                      }
                      width="24"
                      alt=""
                    />
                  </i>{" "}
                </button>
                <br />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

async function hasValidBonusCodeViaUrl(promoCodeUrl, channel, codiceRice) {
  if (!promoCodeUrl) return false;

  try {
    const data = await checkBonusByCode(promoCodeUrl, channel, codiceRice);
    return data.isValid;
  } catch (err) {
    console.error(err);
    return false;
  }
}
