import Next from "../../../images/svg/icon/next.svg";

export default function RegistrationOCRSelectionCard({
  title,
  description,
  onClick,
}) {
  return (
    <button className="ocr-selection-card" onClick={onClick}>
      <h3 className="ocr-selection-card__title">{title}</h3>
      <div className="ocr-selection-card__description">{description}</div>
      <div className="ocr-selection-card__icon">
        <div style={{ width: "14px", display: "flex", alignItems: "center" }}>
          <img className="ocr-selection-card__nextIcon" src={Next} alt="info" />
        </div>
      </div>
    </button>
  );
}
