const ignoreRedirectRouteList = [
  "errore-registrazione",
  "verifica-email",
  "questionario",
  "summary",
  "seleziona-registrazione",
  "seleziona-registrazione-ocr",
  "spid-result-callback",
  "riprendi-registrazione",
  "errore-bonus",
  "seleziona-ocr",
  "ocr-callback",
  "ocr",
  "registrazione-semplificata-pdv",
  "seleziona-documento",
  "registrazione-pdv",
];

export function canRedirectAutomatically() {
  return !ignoreRedirectRouteList.some((value) =>
    window.location.pathname.includes(value),
  );
}
