import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useFlow } from "../../hooks/useFlow";
import { wizardSelectorsFlow } from "../../store/wizard/wizard.selectors";
import { getFlow } from "../../utility/wizardConfig/wizardConfig";
import FullPageLoader from "../elementForm/fullPageLoader/fullPageLoader.component";

export default function RegistrationPdv() {
  const history = useHistory();

  const { goToSpecificStep } = useFlow();

  const defaultClassicFlowName = useSelector(
    wizardSelectorsFlow.getWizardDefaultClassicFlowName,
  );

  useEffect(() => {
    const redirect = async () => {
      await getFlow(defaultClassicFlowName, null, history);
      goToSpecificStep("/registrazione-pdv");
    };

    if (!defaultClassicFlowName) return;

    redirect();
  }, [defaultClassicFlowName, goToSpecificStep, history]);

  return <FullPageLoader />;
}
