import { Translate } from "react-localize-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { utagLink } from "../../../store/utagStore/utagStore.action";
import RegistrationOCRSelectionCard from "../registrationOCRSelectionCard/registrationOCRSelectionCard.component";

export default function ScanDocumentCard() {
  const history = useHistory();

  const dispatch = useDispatch();

  const handleClick = async () => {
    dispatch(
      utagLink({
        reg_event: "inserimento-dati",
        conversion_pagename: "step_0",
        reg_cta: "scansiona documento",
      }),
    );

    history.push("/seleziona-documento/cliente");
  };

  return (
    <RegistrationOCRSelectionCard
      title={<Translate id="text.registrationOCR.scanDocument" />}
      description={
        <Translate id="text.registrationOCR.scanDocumentDescription" />
      }
      onClick={handleClick}
    />
  );
}
