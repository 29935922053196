import { Translate } from "react-localize-redux";
import HomeLogo from "../../../images/svg/Inizio/homeLogo.png";
import ArrowRight from "../../../images/svg/chevron-right.svg";

export default function LinkPdv({ onClick, className }) {
  return (
    <button onClick={onClick} className={`buttonLinkPdv ${className ?? ""}`}>
      <div className="linkIconContainer">
        <img className="linkIcon" src={HomeLogo} alt="home-logo" />
      </div>
      <div className="textLinkPdv">
        <span className="pdvTitle">
          <Translate id="text.openedOnSalePoint" />
        </span>
        <strong>
          <Translate id="lbl.activeHere" />
        </strong>
        <span>
          <Translate id="text.loginWithCredentials" />
        </span>
      </div>
      <div className="arrow">
        <img src={ArrowRight} alt="" />
      </div>
    </button>
  );
}
